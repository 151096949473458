<template>
  <div class="info">
    <van-search
      v-model="searchValue"
      show-action
      placeholder="请输入搜索关键词"
      @search="handlerSearch"
    >
      <template #action>
        <div @click="handlerSearch" style="color: #244e8a">搜索</div>
      </template>
    </van-search>
    <div class="container">
      <van-tabs
        v-model="activeName"
        :line-width="14"
        :line-height="4"
        color="#0069FF"
        title-active-color="#363A44"
        title-inactive-color="#686B73"
        :swipe-threshold="3"
        background="#f6f7f8"
        class="tabs"
        @change="beforeChange"
      >
        <van-tab title="影视企业"><Company ref="company" /></van-tab>
        <van-tab title="电影备案立项公示"><Record ref="record" /></van-tab>
        <van-tab title="电影公映许可证公示"
          ><Permission ref="permission"
        /></van-tab>
        <van-tab title=""></van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Company from './modules/companyList'
import Record from './modules/recordList'
import Permission from './modules/permissionList'
export default {
  components: {
    Company,
    Record,
    Permission,
  },
  data() {
    return {
      searchValue: '',
      activeName: '影视企业',
    }
  },
  methods: {
    beforeChange(e) {
      this.searchValue = ''
    },
    handlerSearch() {
      if (this.activeName === 0) {
        this.$refs.company.getList(this.searchValue, true)
      } else if (this.activeName === 1) {
        this.$refs.record.getList(this.searchValue, true)
      } else if (this.activeName === 2) {
        this.$refs.permission.getList(this.searchValue, true)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  background: #f6f7f8;
  padding: 0 15px;
  min-height: calc(100vh - 56px);
  .tabs {
    margin-bottom: 10px;
  }
}
//tab字体
::v-deep .van-tab--active {
  font-size: 16px;
}
::v-deep .van-tab {
  flex: none;
  padding: 0 6px;
}
</style>
