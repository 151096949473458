<template>
  <div class="permission">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList(name)"
      class="lists"
    >
      <ul class="permission-list">
        <li
          v-for="(item, index) in tableList"
          :key="index"
          @click="goDetail(item.id)"
        >
          <div class="top">
            <div class="name">
              <span class="movie-name">{{ item.name }}</span>
              <span class="movie-type">{{ item.filmType }}</span>
            </div>
            <div class="company">{{ item.dycpdw }}</div>
          </div>
          <div class="bottom">
            <div>
              <span>编码:</span>
              <span>{{ item.code }}</span>
            </div>
            <div>
              <span>公映证号:</span>
              <span>{{ item.gyzh }}</span>
            </div>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      tableList: [],
      pageNo: 1,
      pageSize: 20,
      name: '',
    }
  },
  methods: {
    getList(name, isInit = false) {
      if (isInit) {
        this.pageNo = 1
        this.tableList = []
        this.finished = false
      }
      this.name = name ? name : ''

      this.$axios({
        apiUrl: '/ycc/query/filmGyxkz',
        url: 'mgop.zhijiang.zytv3.ycc0query0filmGyxkz',
        method: 'GET',
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          name: name ? name : '',
        },
      }).then((res) => {
        res.result.records.forEach((item) => {
          this.tableList.push(item)
        })

        // 加载状态结束
        this.loading = false
        //页数+1
        this.pageNo++
        // 数据全部加载完成
        if (res.result.records.length < this.pageSize) {
          this.finished = true
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        path: '/permissionDetail',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.permission {
  width: 100%;
  .lists {
    height: calc(100vh - 110px);
    overflow-y: auto;
    margin-top: 10px;
    .permission-list {
      li {
        width: 100%;
        background: #ffffff;
        border-radius: @border-radius-base;
        margin-bottom: 12px;
        .top {
          padding: 12px;
          box-sizing: border-box;
          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            .movie-name {
              font-size: 16px;
              font-weight: 500;
              color: #363a44;
            }
            .movie-type {
              padding: 4px 10px;
              background: #ebf3fe;
              border-radius: 2px;
              font-size: 12px;
              font-weight: 500;
              color: #244e8a;
            }
          }
          .company {
            font-size: 12px;
            font-weight: 400;
            color: #686b73;
          }
        }
        .bottom {
          height: 40px;
          border-radius: 0px 0px 4px 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 12px;
          border-top: 1px solid #f6f7f8;
          & > div {
            flex: 1;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #686b73;
            }
          }
        }
      }
    }
  }
}
</style>
